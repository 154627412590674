import React, { useContext, useMemo } from "react";
import { RecipientContext } from "../state";
import "./recipient-description.css";

export const RecipientDescription = ({ show }: { show?: boolean }) => {
    const recipient = useContext(RecipientContext);

    const isHtml = recipient.description?.indexOf('<') >= 0;
    const descriptionElement = isHtml && recipient?.description
                                ? <div dangerouslySetInnerHTML={{__html: recipient.description}}></div>
                                : <p>{recipient?.description}</p>;

    return useMemo(() => {
        if (!show) {
            return <></>;
        } else {
            return (
                <div className="recipient-description">
                    {descriptionElement}
                </div>
            );
        }
    }, [show]);
}
