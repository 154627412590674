import { useMemo } from "react";
import { RecipientGoal } from "./recipient-goal";
import { RecipientDonation } from "./recipient-donation";
import { RecipientTeaser } from "./recipient-teaser";
import { RecipientPhoto } from "./recipient-photo";
import { RecipientInfo } from "./recipient-info";
import { RecipientFeaturedBanner } from "./recipient-featured-banner";
import { RecipientCustomFields } from "./recipient-custom-fields";
import { Recipient, RecipientContext } from "../state";
import "./recipient-card.css";

export const RecipientCard = ({ recipient }: { recipient: Recipient }) => {
    return useMemo(() => {
        const partyClass = recipient.politicalParty?.toLowerCase().replaceAll(' ', '-') || "";
        const customField1Class = recipient.customField1?.toLocaleLowerCase().replaceAll(' ', '-') || "";
        const customField2Class = recipient.customField2?.toLocaleLowerCase().replaceAll(' ', '-') || "";
        const featuredClass = recipient.featured ? "featured" : "";
        const classes = [];
        if (partyClass) {
            classes.push(partyClass);
        }

        if (customField1Class) {
            classes.push(customField1Class);
        }

        if (customField2Class) {
            classes.push(customField2Class);
        }

        if (featuredClass) {
            classes.push(featuredClass);
        }

        return (
            <RecipientContext.Provider value={{ ...recipient }}>
                <li className={`recipient-card ${classes.join(" ")}`}>
                    <RecipientFeaturedBanner />
                    <RecipientPhoto />
                    <RecipientInfo />
                    <RecipientGoal />
                    <RecipientTeaser />
                    <RecipientDonation />
                    <RecipientCustomFields />
                </li>
            </RecipientContext.Provider>
        );
    }, [recipient]);
}
