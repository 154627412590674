import React, { useContext, useMemo } from "react";
import { ActionType } from "../actions";
import { RecipientContext, DonationPageStateContext } from "../state";
import { shouldShowProfileDialog } from "./recipient-helper";
import "./recipient-teaser.css";

export const RecipientTeaser = ({ show }: { show?: boolean }) => {
    const { state } = useContext(DonationPageStateContext);
    const recipient = useContext(RecipientContext);
    const slateConfig = state.configuration.slate;
    const recipientTemplateConfig = slateConfig.recipientTemplate;

    // Only show the dialog if the recipient has a description or link
    const { dispatch } = useContext(DonationPageStateContext);

    let showDialog = shouldShowProfileDialog(recipient, slateConfig);

    const dialogHandler = () => {
        dispatch({ type: ActionType.ShowRecipientDialog, recipientId: recipient.identifier });
    }

    const showDialogHandler = showDialog ? dialogHandler : undefined;

    return useMemo(() => {
        if (!show && !recipientTemplateConfig.enableProfileTeaser) {
            return <></>;
        } else {
            return (
                <div className="recipient-teaser">
                    { recipient?.profileTeaser && <p>{recipient?.profileTeaser}</p> }
                    {showDialog && <p className="link" onClick={showDialogHandler}>{slateConfig.recipientDialog.showDialogLabel}</p>}
                </div>
            );
        }
    }, [recipientTemplateConfig.enableProfileTeaser])



}
