import { useContext, useEffect } from "react";
import { useNavigate } from "react-location";
import { FormProvider, SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import { DonationPageStateContext, CheckoutForm } from "../state";
import { DonorCoveredFees } from "./donor-covered-fees";
import { CurrencyFormatter } from "../common/currency";
import { Contact } from "./contact";
import { Employment } from "./employment";
import { Payment } from "./payment";
import { Tip } from "./tip";
import { CheckoutCart } from "./checkout-cart";
import { EventAttendance } from "./event-attendance";
import { CustomCheckbox } from "./custom-checkbox";
import { Terms } from "../common/terms";
import { PageHeader } from "../common/page-header";
import { FetchResponse, ErrorSummary, useFetch } from "../../common";
import { ActionType } from "../actions";
import { ProcessingIndicator } from "../common/processing-indicator";
import { MandatoryFee } from "./mandatory-fee";
import { Attribution } from "./attribution";
import { PacContact } from "./pac-contact";
import "./checkout.scss";

export const Checkout = () => {
    const { state, dispatch } = useContext(DonationPageStateContext);
    const apiErrors = state.apiErrors;
    const navigate = useNavigate();
    const eventConfig = state.configuration.event;
    const checkoutConfig = state.configuration.checkout;
    const donorType = state.donorType;
    const conditionConfig = state.configuration.conditionalDonating;
    const checkoutForm = state.checkoutForm;
    const donateButtonText = state.configuration.conditionalDonating.enabled ? "Pledge" : "Donate";

    const methods = useForm<CheckoutForm>({
        defaultValues: {
            ...checkoutForm
        }
    });

    const {
        handleSubmit,
        watch,
        clearErrors
    } = methods;

    const pagePath = state.urlSlug;

    const apiUrl = state.configuration.conditionalDonating.enabled
                        ? `/api/v2/donation-page/${pagePath}/conditional-donate`
                        : `/api/v2/donation-page/${pagePath}/donate`
    const submitApi = useFetch(apiUrl);

    // Load effects
    useEffect(() => {
        // Clear any state errors
        dispatch({ type: ActionType.UpdateApiErrors, apiErrors: [] });

        // Clear local form errors
        clearErrors();

        // Scroll to top
        window.scrollTo(0, 0);
    }, []);

    // Filter the terms to only those needed in checkout, including any for the tip recipient
    const terms = state.terms;
    let recipientTermIds = state.recipients.filter(r => r.donationAmount?.amount).flatMap(r => r.termIds);
    if (checkoutConfig?.tip?.enabled && checkoutConfig?.tip?.recipient) {
        const tipTermIds = checkoutConfig.tip.recipient.termIds;
        recipientTermIds = recipientTermIds.concat(tipTermIds);
    }
    const checkoutTerms = terms.filter(t => recipientTermIds.indexOf(t.id) >= 0 || t.id == "Default" || t.id == "Earmarking");

    // Ensure the DonationPageStateContext has the updated CheckoutForm data
    useEffect(() => {
        const subscription = watch((d) => {
            dispatch({ type: ActionType.UpdateCheckoutForm, checkoutForm: d as CheckoutForm })
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    const onSubmit: SubmitHandler<CheckoutForm> = (data: CheckoutForm) => {
        // Ensure the state has the updated CheckoutForm data
        dispatch({ type: ActionType.UpdateCheckoutForm, checkoutForm: data });

        const body = {
            ...data,
            payment: {
                ...data.payment,
                creditCard: data.payment.useStoredPaymentAuthorization ? null : data.payment.creditCard,
                electronicCheck: data.payment.useStoredPaymentAuthorization ? null : data.payment.electronicCheck
            },
            donorCoveredFees: {
                enabled: data.donorCoveredFees.enabled,
                amount: state.cartAmounts.donorCoveredFees
            },
            mandatoryFee: {
                amount: state.cartAmounts.mandatoryFee
            },
            page: pagePath,
            recipients: state.recipients
                .filter(r => r.donationAmount != null && r.donationAmount.amount > 0)
                .map(r => { return { identifier: r.identifier, amount: r.donationAmount.amount, attribution: r.attribution } }),
            sourceCode: state.sourceCode,
            createSubscription: state.createSubscription,
            utmTracking: state.utmTracking
        }

        if (body.payment.paymentMethod == "ElectronicCheck") {
            body.payment.creditCard = null;
        }

        dispatch({ type: ActionType.ShowProcessingIndicator });

        submitApi.post(body)
            .then((response: FetchResponse) => {
                dispatch({ type: ActionType.HideProcessingIndicator });

                if (response.success) {
                    dispatch({ type: ActionType.UpdateApiErrors, apiErrors: [] });
                    navigate({ to: "../confirmation", replace: true });
                } else {
                    dispatch({ type: ActionType.UpdateApiErrors, apiErrors: response.errors });
                }
            });
    }

    const contactForm = donorType == "FederalPac" ? <PacContact /> : <Contact />

    if (!checkoutConfig) {
        return <>No checkout</>;
    } else {
        return (
        <>
            <PageHeader config={checkoutConfig.header} pageClassName="checkout-page" />
            <FormProvider {...methods}>
                <div className="checkout content">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ErrorSummary apiErrors={apiErrors} />
                        {checkoutConfig?.customCheckbox?.enabled && <CustomCheckbox />}
                        {eventConfig?.enabled && <EventAttendance />}
                        <Attribution />
                        {contactForm}
                        { state.donorType != "FederalPac" && <Employment /> }
                        <Payment />
                        {checkoutConfig?.tip?.enabled && <Tip />}
                        {checkoutConfig?.donorCoveredFees?.enabled && <DonorCoveredFees />}
                        {checkoutConfig?.mandatoryFee?.enabled && <MandatoryFee />}
                        <Terms showDeTerms={true} />
                        <fieldset>
                                <button disabled={submitApi.isLoading || state.cartAmounts.total == 0} type="submit" className="checkout-button">{donateButtonText} {CurrencyFormatter.format(state.cartAmounts.total).replace(/\.00/, "")}</button>
                        </fieldset>
                        <Terms terms={checkoutTerms} conditionalDonatingConfig={conditionConfig} />
                    </form>
                    <div className="checkout-tools">
                        <CheckoutCart />
                    </div>
                    <ProcessingIndicator />
                </div>
            </FormProvider>
        </>
        );
    }
}
