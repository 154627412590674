import React, { useContext } from "react";
import { ActionType } from "../actions";
import { DonationPageStateContext, RecipientContext } from "../state";
import "./recipient-info.css";

const interpolate = function(template: string, params: any) {
    const names = Object.keys(params);
    const vals = Object.values(params);
    return new Function(...names, `return \`${template}\`;`)(...vals);
}

export const RecipientInfo = ({ disableDialog = false }: { disableDialog?: boolean }) => {
    const { state } = useContext(DonationPageStateContext);
    const recipient = useContext(RecipientContext);
    const customTemplate = state.configuration.slate.recipientTemplate.infoTemplate;

    let infoTemplate: JSX.Element | null = null;

    const recipientInfoClass = disableDialog ? "recipient-info" : "recipient-info clickable";

    // Only show the dialog if the recipient has a description or link
    const { dispatch } = useContext(DonationPageStateContext);

    let showDialog = false;

    if (state.configuration.slate.recipientDialog.enabled && !disableDialog) {
        showDialog = recipient.description
            || recipient.links.facebook
            || recipient.links.instragram
            || recipient.links.site
            || recipient.links.twitter
            || recipient.links.youTube ? true : false;
    }

    const dialogHandler = () => {
        dispatch({ type: ActionType.ShowRecipientDialog, recipientId: recipient.identifier });
    }

    const showDialogHandler = showDialog ? dialogHandler : undefined;

    if (customTemplate) {
        const innerHtml = interpolate(customTemplate, { recipient: recipient });
        infoTemplate = <div className="recipient-info" dangerouslySetInnerHTML={{ __html: innerHtml }}></div>;
    } else {
        const partyClass = (recipient.politicalParty || "").toLowerCase();
        const party = recipient.politicalParty
            ? <span className={`party ${partyClass}`}>{recipient.politicalParty?.substring(0, 1)}</span>
            : <></>;
        infoTemplate =
            <div className={recipientInfoClass} onClick={showDialogHandler} >
                <div className="name">
                    {recipient.displayName}
                    {party}
                </div>
                {recipient.office && <div className="office">{recipient.office}</div>}
                {recipient.district && <div className="district">{recipient.district}</div>}
            </div>
}

    return (
        infoTemplate
    );
}
