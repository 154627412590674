import * as React from "react";
import { get, useFormContext } from "react-hook-form";

import { useFieldFormContext, ErrorMessage } from "../../common";

import { DonationPageStateContext } from "../state";
import { PaymentCreditCard } from "./payment-credit-card";
import { PaymentElectronicCheck } from "./payment-electronic-check";

import "./payment.scss";

export const Payment = () => {
    const { state } = React.useContext(DonationPageStateContext);
    const { register, watch } = useFormContext();
    const { registerField } = useFieldFormContext();

    const paymentAuthorizationSaved = state.currentUser?.paymentAuthorizationSaved;
    const useStoredPaymentAuthorization = watch("payment.useStoredPaymentAuthorization");
    const paymentMethod = watch("payment.paymentMethod");
    const rememberPayment = watch("payment.rememberPayment");
    const password = watch("payment.password");
    const userAuthenticated = state.currentUser != null;

    const fields = {
        rememberPayment: {
            id: "rememberPayment",
            name: "payment.rememberPayment"
        },
        useStoredPaymentAuthorization: {
            id: "useStoredPaymentAuthorization",
            name: "payment.useStoredPaymentAuthorization"
        },
        password: {
            id: "password",
            name: "payment.password",
            options: {
                maxLength: 40,
                minLength: { value: 10, message: "Password must have at least 10 characters" }
            }
        },
        confirmPassword: {
            id: "confirmPassword",
            name: "payment.confirmPassword",
            options: {
                validate: { match: (v: any) => v != password ? "Passwords must match": null }
            }
        }
    }

    const rememberPasswordText = paymentAuthorizationSaved
        ? "Replace my saved payment information with the information above"
        : "Remember my payment information";

    return (
        <fieldset className={`payment ${paymentMethod}`}>
            <legend>Payment</legend>

            {paymentAuthorizationSaved &&
                <div className="form-group callout use-stored-payment-authorization">
                    <label htmlFor="useStoredPaymentAuthorization" className="checkbox">
                        <input type="checkbox" {...registerField(fields.useStoredPaymentAuthorization)}></input>
                        Use my saved payment information ({state.currentUser?.paymentAuthorizationDisplayName})
                    </label>
                </div>}

            {!useStoredPaymentAuthorization && <>
                <div className="form-group payment-method">
                    <label htmlFor="creditCard">
                        <input type="radio" id="creditCard" {...register("payment.paymentMethod")} value="CreditCard" /> Credit Card
                    </label>
                    <label>
                        <input type="radio" id="electronicCheck" {...register("payment.paymentMethod")} value="ElectronicCheck" /> Electronic Check
                    </label>
                </div>
                {paymentMethod == "CreditCard" && <PaymentCreditCard />}
                {paymentMethod === "ElectronicCheck" && <PaymentElectronicCheck />}
                <div className="form-group remember">
                    <label htmlFor="rememberPayment">
                        <input type="checkbox" {...registerField(fields.rememberPayment)}></input>
                        {rememberPasswordText}
                    </label>
                </div>
                {rememberPayment && !userAuthenticated &&
                    <>
                        <div className="form-group password">
                            <label htmlFor={fields.password.id}>Password</label>
                            <input type="password" {...registerField(fields.password)} />
                            <ErrorMessage name={fields.password.name} />
                        </div>
                        <div className="form-group confirm-password">
                            <label htmlFor={fields.confirmPassword.id}>Confirm Password</label>
                            <input type="password" {...registerField(fields.confirmPassword)} />
                            <ErrorMessage name={fields.confirmPassword.name} />
                        </div>
                    </>}
            </>}
        </fieldset>
    );
}