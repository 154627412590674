import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CurrencyFormatter } from "../common/currency";
import { DonationPageStateContext } from "../state";
import "./mandatory-fee.scss";

export const MandatoryFee = () => {
    const { state } = useContext(DonationPageStateContext);
    const { formState: { errors } } = useFormContext();
    const config = state.configuration.checkout.mandatoryFee;

    const feesAmount = state.cartAmounts.mandatoryFee;

    return (
        <fieldset className="mandatory-fee callout">
            <legend>{config.title}</legend>
            {config.content && <p>{config.content} <span>({CurrencyFormatter.format(feesAmount)})</span></p>}
        </fieldset>
    );
}
