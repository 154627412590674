import { useContext } from "react";
import { ActionType } from "../actions";
import { DonationPageStateContext } from "../state";
import "./featured-toggle.css";

export const FeaturedToggle = () => {
    const { state, dispatch } = useContext(DonationPageStateContext);

    const updateFilters = (filtered: boolean) => {
        const filters = {
            ...state.filters,
            featured: filtered
        };

        dispatch({ type: ActionType.UpdateFilters, filters: filters });
    }

    const featuredConfig = state.configuration.slate.featuredRecipients;

    const featuredClasses = state.filters.featured ? "featured selected" : "featured";
    const allClasses = state.filters.featured ? "all" : "all selected";

    if (!featuredConfig.enabled) {
        return <></>;
    } else {
        return (
            <section className="featured-toggle">
                <label className={featuredClasses}><span><input type="radio" name="featured" value="true" checked={state.filters.featured} onChange={e => updateFilters(true)} />{featuredConfig.viewFeaturedText}</span></label>
                <label className={allClasses}><span><input type="radio" name="featured" value="false" checked={!state.filters.featured} onChange={e => updateFilters(false)} />{featuredConfig.viewAllText}</span></label>
            </section>
        );
    }
}
