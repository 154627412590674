import { useContext } from "react";
import { uniq, filter, map } from "lodash";
import { DonationPageStateContext } from "../state";
import { ActionType } from "../actions";
import {
    MultiSelect,
    MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import "./slate-filters.css";

export const SlateFilters = () => {
    const { state, dispatch } = useContext(DonationPageStateContext);

    const handleChange = (event: MultiSelectChangeEvent) => {
        if (event.target.name) {
            const filters: any = {
                ...state.filters,
            };

            filters[event.target.name] = event.value;

            dispatch({ type: ActionType.UpdateFilters, filters: filters });
        }
    }

    const handleFilterByName = (event: TextBoxChangeEvent) => {
        if (event.target.element) {
            const filters: any = {
                ...state.filters,
            };

            filters[event.target.element.name] = event.value;
            dispatch({ type: ActionType.UpdateFilters, filters: filters });
        }
    }

    const filterConfig = state.configuration.slate.filters;

    if (!filterConfig.enabled) {
        return <></>
    } else {
        const filters = [];

        const getOptions = (field: string) => {
            return uniq(filter(map(state.recipients, field), (p) => p != null)).sort();
        }

        const offices = getOptions("office");
        const states = getOptions("state");
        const politicalParties = getOptions("politicalParty");
        const customField1Options = getOptions("customField1");
        const customField2Options = getOptions("customField2");

        if (filterConfig.displayName && state.recipients.length > 20) {
            filters.push(<TextBox size="large" rounded={null} key="displayName" name="displayName" placeholder="Filter by Name" autoComplete="off" onChange={handleFilterByName} />);
        }

        if (filterConfig.office && offices.length > 0) {
            filters.push(<MultiSelect size="large" rounded={null} key="offices" name="offices" data={offices} value={state.filters.offices} placeholder="Filter by Office" onChange={handleChange} />)
        }

        if (filterConfig.state && states.length > 0) {
            filters.push(<MultiSelect size="large" rounded={null} key="states" name="states" data={states} value={state.filters.states} placeholder="Filter by State" onChange={handleChange} />)
        }

        if (filterConfig.politicalParty && politicalParties.length > 0) {
            filters.push(<MultiSelect size="large" rounded={null} key="politicalParties" name="politicalParties" data={politicalParties} value={state.filters.politicalParties
} placeholder="Filter by Party" onChange={handleChange} />)
        }

        if (filterConfig.customField1 && customField1Options.length > 0) {
            filters.push(<MultiSelect size="large" rounded={null} key="customField1" className="custom-field1-filter" name="customField1" data={customField1Options} placeholder={"Filter by " + filterConfig.customField1.label} onChange={handleChange} />)
        }

        if (filterConfig.customField2 && customField2Options.length > 0) {
            filters.push(<MultiSelect size="large" rounded={null} key="customField2" className="custom-field2-filter" name="customField2" data={customField2Options} placeholder={"Filter by " + filterConfig.customField2.label} onChange={handleChange} />)
        }

        return (
            <section className="slate-filters">
                {filters}
            </section>
        );
    }
}
