import { useContext } from "react";
import { DonationPageStateContext } from "../state";

import "./notification-banner.css";

const formatAmount = (amt: number) => {
    return new Intl
        .NumberFormat("en-US", { style: "currency", currency: "USD" })
        .format(amt)
        .replace(".00", "");
}

export const NotificationBanner = () => {
    const { state } = useContext(DonationPageStateContext);

    if (!state.configuration.donationLimit.enabled) {
        return <></>;
    }

    const donationLimitReached = state.donationLimit.limitReached;
    if (!donationLimitReached) {
        return <></>
    }

    // This should be moved somewhere central, probably the reducer
    const donatedAmount = state.donationLimit.donatedAmount;

    let message = "";

    if (donatedAmount == 0) {
        message = `You are limited to donating ${formatAmount(state.configuration.donationLimit.limit)} via this page. Please adjust your donation amount accordingly.`;
    } else if (state.configuration.donationLimit.remaining > 0) {
        message = `You are limited to donating ${formatAmount(state.configuration.donationLimit.limit)} via this page and you have already donated ${ formatAmount(donatedAmount) }. Please adjust your donation amount accordingly.`;
    } else {
        message = `You are limited to donating ${formatAmount(state.configuration.donationLimit.limit)} via this page and you have already donated ${formatAmount(donatedAmount)}.`;
    }

    return (<section className="notification-banner">
        {message}
    </section>)
}
