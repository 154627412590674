import { useContext } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { ErrorMessage } from "../../common";
import { ActionType } from "../actions";
import { AttributionList, CheckoutForm, DonationPageStateContext } from "../state";
import "./attribution.scss";

export const Attribution = () => {
    const { state, dispatch } = useContext(DonationPageStateContext);
    const { register, formState: { errors } } = useFormContext<CheckoutForm>();
    const config = state.configuration.checkout.donationAttribution;

    const recipientsWithDonationAmounts = state.recipients
        .filter(r => r.donationAmount && r.donationAmount.amount > 0);

    const recipientsWithAttributionListIds = recipientsWithDonationAmounts.filter(r => r.attributionListId);

    // HANDLERS
    const handleChange = (recipientId: string, attribution: string) => {
        dispatch({ type: ActionType.UpdateRecipientAttribution, identifier: recipientId, attribution: attribution });
    }

    // RENDER
    if (!config.enabled || recipientsWithAttributionListIds.length == 0) {
        return <></>;
    }

    // Content
    const htmlInContent = config.content?.indexOf(">") > -1 || false;
    const contentElement = htmlInContent ?
        <div dangerouslySetInnerHTML={{ __html: config.content }}></div>
        : (config.content && <p>{config.content.replaceAll("&nbsp;", "")}</p>);

    const recipientFormGroups = recipientsWithAttributionListIds
        .map((r, index) => {
            // Get the attribution list for this recipient and use its members
            const attributionList = config.attributionLists.find(al => al.id == r.attributionListId) as AttributionList;
            const members = attributionList?.members;
            const options = members.map(m => <option key={m}>{m}</option>);

            // Get the error message to display below the select element
            let errorMessage = "";
            if (errors.attributionFields) {
                let fe: FieldError | undefined = errors.attributionFields[index];
                if (fe) {
                    errorMessage = fe.message || "";
                }
            }

            return (<div className="form-group" key={r.identifier}>
                <label htmlFor={"attribution-" + r.identifier}>{r.displayName}</label>
                <select id={"attribution-" + r.identifier} {...register(`attributionFields.${index}`, { required: { value: config.required, message: `Attribution for ${r.displayName} is required`} })} onChange={(e) => handleChange(r.identifier, e.target.value)}>
                    <option value=""></option>
                    {options}
                </select>
                {errorMessage && <span className="error-message">{errorMessage}</span>}
            </div>);
            }
        );

    return (
        <fieldset className="attribution">
            <legend>{config.title}</legend>
            {contentElement}

            <div className="recipients">
                {recipientFormGroups}
            </div>
        </fieldset>
    );
}
