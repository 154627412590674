import { ConditionalDonatingConfiguration, Term } from "../state";

import "./terms.css";

interface TermsProps { 
    terms?: Term[], 
    conditionalDonatingConfig?: ConditionalDonatingConfiguration, 
    showDeTerms?:boolean 
}

export const Terms = ({ terms = [], conditionalDonatingConfig = { enabled: false, conditionContent: "" }, showDeTerms = false }: TermsProps) => {

    const termParagraphs = terms.map((t) => {
        if (t.content?.indexOf("<") >= 0) {
            return <div key={t.id} dangerouslySetInnerHTML={{ __html: t.content }}></div>
        } else {
            return <p key={t.id}>{t.content}</p>;
        }
    });

    // Conditional Donating Terms
    let conditionalDonatingContent = <></>;

    if (conditionalDonatingConfig.enabled) {

        if (conditionalDonatingConfig.conditionContent?.indexOf("<") >= 0) {
            conditionalDonatingContent = <div dangerouslySetInnerHTML={{ __html: conditionalDonatingConfig.conditionContent }}></div>
        } else {
            conditionalDonatingContent = <p>{conditionalDonatingConfig.conditionContent}</p>
        }

        conditionalDonatingContent = <div className="condition"><p>I agree that my Pledge can be collected (and my payment method debited) when the following condition is met:</p> {conditionalDonatingContent}</div>
    }

    const donateText = conditionalDonatingConfig.enabled ? "Pledge" : "Donate";

    return (
        <section className="terms">
            { showDeTerms && <p className="de-terms">I understand that my payment is being made to Democracy Engine, who accepts the responsibility of distributing the funds to my designated recipient(s) in accordance with applicable campaign finance laws. My card or bank statement will show a payment to “Democracy Engine” or “DEMENG” and if there are any issues or discrepancies with the payment or I wish to revoke the payment, I can contact Democracy Engine at <a href="mailto: support@democracyengine.com">support@democracyengine.com</a>. Democracy Engine will charge each recipient an applicable service fee.</p> }

            { conditionalDonatingContent }

            { termParagraphs.length > 0 && <p>By clicking on the “{donateText}” button you confirm that the following statements are true and accurate:</p> }

            { termParagraphs }
            
        </section>
    );
}
