import { CartAmounts, DonationPageState } from "./state";

export const calculateCartAmounts = (state: DonationPageState): CartAmounts => {
    const checkoutForm = state.checkoutForm;
    const checkoutConfig = state.configuration.checkout;

    // Get and calculate base and tip amounts
    const baseAmount = state.recipients.reduce((acc, r) => acc + (r.donationAmount.amount || 0), 0);
    const tipAmount = checkoutForm.tip.amount || 0;
    const baseAndTipAmount = baseAmount + tipAmount;

    // Determine donor-covered fees amount and update form state value if needed
    const paymentMethod = checkoutForm.payment.paymentMethod;

    const donorCoveredFeeBase = paymentMethod === "ElectronicCheck" ? checkoutConfig.donorCoveredFees.electronicCheckBase
        : checkoutConfig.donorCoveredFees.creditCardBase;

    const donorCoveredFeeRate = paymentMethod === "ElectronicCheck" ? checkoutConfig.donorCoveredFees.electronicCheckRate
        : checkoutConfig.donorCoveredFees.creditCardRate;

    const donorCoveredFeesAmount = donorCoveredFeeBase + (baseAndTipAmount * donorCoveredFeeRate);

    // Determine mandatory fee amount and update form state value if needed
    const mandatoryFeeConfig = checkoutConfig.mandatoryFee;
    const includeMandatoryFee = mandatoryFeeConfig.enabled && baseAndTipAmount > 0;

    let mandatoryFeeAmount = mandatoryFeeConfig.baseAmount + (baseAndTipAmount * mandatoryFeeConfig.rate);
    if (mandatoryFeeConfig.maximumAmount && mandatoryFeeAmount > mandatoryFeeConfig.maximumAmount) {
        mandatoryFeeAmount = mandatoryFeeConfig.maximumAmount;
    }

    // Calculate the total amount and update form state value if needed
    let totalAmount = baseAndTipAmount;
    const includeDonorCoveredFees = checkoutForm.donorCoveredFees.enabled;

    if (includeDonorCoveredFees && baseAndTipAmount > 0) {
        totalAmount += donorCoveredFeesAmount
    }

    if (includeMandatoryFee && baseAndTipAmount > 0) {
        totalAmount += mandatoryFeeAmount
    }

    return {
        base: baseAmount,
        total: totalAmount,
        mandatoryFee: mandatoryFeeAmount,
        donorCoveredFees: donorCoveredFeesAmount,
        tip: tipAmount
    }
}