import * as React from "react";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useFieldFormContext, ErrorMessage } from "../../common";
import { DonationPageStateContext } from "../state";
import "./employment.scss";

export const Employment = () => {

    const { state } = useContext(DonationPageStateContext);
    const { watch } = useFormContext();
    const { registerField } = useFieldFormContext();
    const checkoutConfiguration = state.configuration.checkout;
    const employmentNote = checkoutConfiguration.employmentNote;

    const employmentStatus = watch("employment.status");

    let requireEmployerAddress: boolean = false;

    if (employmentStatus == "Employed") {
        requireEmployerAddress = state.recipients.some(r => r.donationAmount?.amount > 0 && r.requireEmployerAddress == true);
    }

    const fields = {
        employmentStatus: {
            id: "employmentStatus",
            name: "employment.status"
        },
        employer: {
            id: "employer",
            name: "employment.employer",
            options: {
                required: { value: true, message: "Enter an employer" },
                maxLength: 50
            }
        },
        employerStreetAddress: {
            id: "streetAddress",
            name: "employment.streetAddress",
            options: {
                required: { value: requireEmployerAddress, message: "Enter an employer address" },
                maxLength: 50
            }
        },
        employerCity: {
            id: "city",
            name: "employment.city",
            options: {
                required: { value: requireEmployerAddress, message: "Enter an employer city" },
                maxLength: 50
            }
        },
        employerState: {
            id: "state",
            name: "employment.state",
            options: {
                required: { value: requireEmployerAddress, message: "Select an employer state" },
                maxLength: 2
            }
        },
        employerPostalCode: {
            id: "postalCode",
            name: "employment.postalCode",
            options: {
                required: { value: requireEmployerAddress, message: "Enter an employer ZIP code" },
                maxLength: 10
            }
        },
        occupation: {
            id: "occupation",
            name: "employment.occupation",
            options: {
                required: { value: true, message: "Enter an occupation" },
                maxLength: 50
            }
        }
    }

    const employerAddress = (
        <>
            <div className="form-group street-address">
                <label htmlFor={fields.employerStreetAddress.id}>Employer Address</label>
                <input type="text" {...registerField(fields.employerStreetAddress)} />
                <ErrorMessage name={fields.employerStreetAddress.name} />
            </div>
            <div className="form-group city">
                <label htmlFor={fields.employerCity.id}>Employer City</label>
                <input type="text" {...registerField(fields.employerCity)} />
                <ErrorMessage name={fields.employerCity.name} />
            </div>
            <div className="form-group state">
                <label htmlFor={fields.employerState.id}>Employer State</label>
                <select {...registerField(fields.employerState)}>
                    <option value=""></option>
                    <option value="AK">AK</option>
                    <option value="AL">AL</option>
                    <option value="AR">AR</option>
                    <option value="AZ">AZ</option>
                    <option value="CA">CA</option>
                    <option value="CO">CO</option>
                    <option value="CT">CT</option>
                    <option value="DC">DC</option>
                    <option value="DE">DE</option>
                    <option value="FL">FL</option>
                    <option value="GA">GA</option>
                    <option value="HI">HI</option>
                    <option value="IA">IA</option>
                    <option value="ID">ID</option>
                    <option value="IL">IL</option>
                    <option value="IN">IN</option>
                    <option value="KS">KS</option>
                    <option value="KY">KY</option>
                    <option value="LA">LA</option>
                    <option value="MA">MA</option>
                    <option value="MD">MD</option>
                    <option value="ME">ME</option>
                    <option value="MI">MI</option>
                    <option value="MN">MN</option>
                    <option value="MO">MO</option>
                    <option value="MS">MS</option>
                    <option value="MT">MT</option>
                    <option value="NC">NC</option>
                    <option value="ND">ND</option>
                    <option value="NE">NE</option>
                    <option value="NH">NH</option>
                    <option value="NJ">NJ</option>
                    <option value="NM">NM</option>
                    <option value="NV">NV</option>
                    <option value="NY">NY</option>
                    <option value="OH">OH</option>
                    <option value="OK">OK</option>
                    <option value="OR">OR</option>
                    <option value="PA">PA</option>
                    <option value="RI">RI</option>
                    <option value="SC">SC</option>
                    <option value="SD">SD</option>
                    <option value="TN">TN</option>
                    <option value="TX">TX</option>
                    <option value="UT">UT</option>
                    <option value="VA">VA</option>
                    <option value="VT">VT</option>
                    <option value="WA">WA</option>
                    <option value="WI">WI</option>
                    <option value="WV">WV</option>
                    <option value="WY">WY</option>
                    <option value="AS">AS</option>
                    <option value="GU">GU</option>
                    <option value="MP">MP</option>
                    <option value="PR">PR</option>
                    <option value="UM">UM</option>
                    <option value="VI">VI</option>
                </select>
                <ErrorMessage name={fields.employerState.name} />
            </div>
            <div className="form-group postal-code">
                <label htmlFor={fields.employerPostalCode.id}>Employer ZIP Code</label>
                <input type="text" {...registerField(fields.employerPostalCode)} />
                <ErrorMessage name={fields.employerPostalCode.name} />
            </div>
        </>);

    return (
        <fieldset className="employment">
            <legend>Employment</legend>
            <div className="form-group status">
                <label htmlFor={fields.employmentStatus.id}>Employment Status</label>
                <select {...registerField(fields.employmentStatus)}>
                    <option value="Employed">Employed</option>
                    <option value="Retired">Retired</option>
                    <option value="NotEmployed">Not Employed</option>
                </select>
                {employmentNote && <span className="employment-note">{employmentNote}</span>}
            </div>
            {employmentStatus == "Employed" && (
                <>
                    <div className="form-group employer">
                        <label htmlFor={fields.employer.id}>Employer</label>
                        <input type="text" {...registerField(fields.employer)}></input>
                        <ErrorMessage name={fields.employer.name} />
                    </div>
                    <div className="form-group occupation">
                        <label htmlFor={fields.occupation.id}>Occupation</label>
                        <input type="text" {...registerField(fields.occupation)}></input>
                        <ErrorMessage name={fields.occupation.name} />
                    </div>
                    {requireEmployerAddress && employerAddress}
                </>
            )}
        </fieldset>
    );
}