import * as React from "react";
import { useContext } from "react";
import { CurrencyFormatter } from "../common/currency";
import { DonationPageStateContext } from "../state";
import { ActionType } from "../actions";
import { useNavigate } from "react-location";
import { FetchResponse, useFetch } from "../../common";
import "./slate-cart.css";

export const SlateCart = ({detailed}: {detailed?: boolean}) => {
    const { state, dispatch } = useContext(DonationPageStateContext);
    const navigate = useNavigate()

    const cartAmounts = state.cartAmounts;

    const showExpressDonate = state.currentUser?.paymentAuthorizationSaved
        && !state.configuration.event.enabled
        && !state.configuration.checkout.donationAttribution.enabled
        && !state.configuration.checkout.mandatoryFee.enabled
        && !state.configuration.conditionalDonating.enabled
        && state.donorType != "FederalPac";

    const donationLimitReached = state.donationLimit.limitReached;
    const validDonationAmount = !donationLimitReached && cartAmounts.total > 0;

    const recipientListItems = state.recipients
        .filter(r => r.donationAmount.amount > 0)
        .map(r =>
            <li key={r.identifier}>{r.displayName}: {CurrencyFormatter.format(r.donationAmount.amount)}</li>
    );

    if (cartAmounts.mandatoryFee) {
        recipientListItems.push(<li key="mandatory-fee"><span>{state.configuration.checkout.mandatoryFee.lineItemText}:</span> <span>{CurrencyFormatter.format(cartAmounts.mandatoryFee)}</span></li>);
    }

    const navigateToCheckout = () => {
        if (validDonationAmount) {
            navigate({ to: "./checkout", replace: false });
        }
    }

    const pagePath = window.location.pathname.split("/").filter(p => p != "").pop();
    const expressApi = useFetch(`/api/v2/donation-page/${pagePath}/express-donate`);
    const expressDonate = () => {
        if (cartAmounts.total <= 0) {
            return;
        }

        const body = {
            createSubscription: state.createSubscription,
            page: pagePath,
            sourceCode: state.sourceCode,
            recipients: state.recipients.filter(r => r.donationAmount.amount > 0).map(r => { return { identifier: r.identifier, amount: r.donationAmount.amount } }),
            mandatoryFee: { amount: cartAmounts.mandatoryFee },
            utmTracking: state.utmTracking
        }

        dispatch({ type: ActionType.ShowProcessingIndicator });

        expressApi.post(body)
            .then((response: FetchResponse) => {
                if (response.success) {
                    navigate({ to: "../confirmation", replace: true });
                } else {
                    dispatch({ type: ActionType.UpdateApiErrors, apiErrors: response.errors });
                    navigate({ to: "./checkout", replace: true });
                }

                dispatch({ type: ActionType.HideProcessingIndicator });
            });
    }

    const updateSubscriptionStatus = (subscribe: boolean) => {
        dispatch({ type: ActionType.UpdateSubscriptionStatus, subscribe: subscribe });
    }

    const formatAmount = (amt: number) => {
        return new Intl
            .NumberFormat("en-US", { style: "currency", currency: "USD" })
            .format(amt)
            .replace(".00", "");
    }

    const header = <h1>{formatAmount(cartAmounts.base)}</h1>;

    const continueButton = <button type="button" onClick={navigateToCheckout} disabled={!validDonationAmount}>Continue</button>

    const expressDonateButton = <button className="express" type="button" onClick={expressDonate} disabled={!validDonationAmount}>Express Donate
                                    <span className="payment-information">Using {state.currentUser?.paymentAuthorizationDisplayName}</span>
                                </button>;

    const clearButton = cartAmounts.total > 0
        ? <span className="clear" onClick={() => dispatch({ type: ActionType.ClearRecipientAmounts })}>Clear</span>
        : <></>

    const subscriptionCheckbox = state.configuration.slate.subscription.enabled
        ? <label className="subscription"><input type="checkbox" checked={state.createSubscription} onChange={(e) => updateSubscriptionStatus(e.target.checked)} /> Make this a monthly donation</label>
        : <></>

    if (showExpressDonate) {
        return (
            <section className="slate-cart" aria-label="Cart">
                {header}
                {subscriptionCheckbox}
                {expressDonateButton}
                <span className="link continue-without-express" onClick={navigateToCheckout}>Continue without using Express Donate</span>
                {clearButton}
            </section>
        );
    }
    else {
        return (
            <section className="slate-cart" aria-label="Cart">
                {header}
                {detailed && <ul>
                    {recipientListItems}
                </ul>}
                {continueButton}
                {subscriptionCheckbox}
                {clearButton}
            </section>
        );
    }
}
