import * as React from "react";
import { useContext } from "react";
import { DonationPageStateContext, Header } from "../state";
import "./page-header.css";

export const PageHeader = ({ config, pageClassName }: { config: Header, pageClassName?: string }) => {

    const { state } = useContext(DonationPageStateContext);

    const unpublishedBanner = state.configuration.status == "Unpublished" || state.configuration.status == "Archived" || state.configuration.status == "ReadyForReview"
        ? <div className="unpublished-banner">THIS PAGE IS UNPUBLISHED. ONLY ADMINISTRATORS CAN VIEW.</div>
        : <></>;

    const fullHtmlHeader = config.html ? true : false;
    const className = `page-header ${pageClassName}`;

    // Return nothing if no header (useful on single recipient pages)
    if (!config.html && !config.title && !config.content) {
        return <></>;
    }

    // Return full custom header if html is specified
    if (fullHtmlHeader) {
        return <header className={className}>{unpublishedBanner} <div dangerouslySetInnerHTML={{ __html: config.html }}></div></header>
    }

    // Return the standard title and content, checking to see if content itself has HTML
    const htmlInContent = config?.content?.indexOf(">") > -1 || false;

    const contentElement = htmlInContent ?
        <div dangerouslySetInnerHTML={{ __html: config.content }}></div>
        : (config.content && <p>{config.content.replaceAll("&nbsp;", "")}</p>);

    return <header className={className}>
               {unpublishedBanner}
               <h1>{config.title}</h1>
               {contentElement}
           </header>;
}
