import { useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import { DonationButtons } from "../common/donation-buttons";
import { CheckoutForm, DonationAmount, DonationPageStateContext, OtherAmount } from "../state";
import "./tip.scss";

export const Tip = () => {
    // App state
    const { state } = useContext(DonationPageStateContext);
    const tipConfiguration = state.configuration.checkout.tip;

    // Form data
    const { setValue, getValues } = useFormContext<CheckoutForm>();
    const checkoutForm = getValues();

    // Component state
    const [localDonationAmount, setLocalDonationAmount] = useState<DonationAmount>(new OtherAmount(checkoutForm.tip.amount));

    const onChangeHandler = (donationAmount: DonationAmount) => {
        setValue("tip.amount", donationAmount.amount);
        setLocalDonationAmount(donationAmount);
    }

    const tipContent = tipConfiguration.content ? <p>{tipConfiguration.content}</p> : <></>;

    return (
        <fieldset className="tip">
            <legend>{tipConfiguration.title}</legend>
            {tipContent}
            <DonationButtons
                suggestedDonationAmounts={tipConfiguration.suggestedAmounts}
                donationAmount={localDonationAmount}
                limit={tipConfiguration.recipient?.contributionLimit || 0}
                onChange={onChangeHandler} />
        </fieldset>
    );
}
