import { useContext } from "react";
import { DonationButtons } from "../common/donation-buttons";
import { DonationAmount, DonationPageStateContext, RecipientContext } from "../state";
import { ActionType } from "../actions";
import "./recipient-donation.css";

export const RecipientDonation = () => {

    const { state, dispatch } = useContext(DonationPageStateContext);
    const recipient = useContext(RecipientContext);

    const onChangeHandler = (donationAmount: DonationAmount) => {
        dispatch({ type: ActionType.UpdateRecipientAmount, identifier: recipient.identifier, amount: donationAmount });
    }

    //return useMemo(() => {
        return (
            <div className="recipient-donation">
                {recipient.pausedStatus.paused && <div dangerouslySetInnerHTML={{__html:recipient.pausedStatus.message}}></div> }
                {!recipient.pausedStatus.paused &&
                    <DonationButtons
                        suggestedDonationAmounts={state.configuration.slate.suggestedDonationAmounts}
                        donationAmount={recipient.donationAmount}
                        limit={recipient.contributionLimit}
                        onChange={onChangeHandler} />
                }
            </div>
        );
    //}, [selectedAmount]);
}
