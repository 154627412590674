import { Dialog } from "@progress/kendo-react-dialogs";
import React, { useContext } from "react";
import { DonationPageStateContext } from "../state";
import { ActionType } from "../actions";

export const ProcessingIndicator = () => {
    const { state, dispatch } = useContext(DonationPageStateContext);
    const indicator = state.processingIndicator;

    return (<>{indicator.show &&
        <Dialog className="processing-indicator">
            <h1 style={{ margin: "0.5rem 1rem 0" }}>Processing</h1>
            {indicator.message &&
                <p>{indicator.message}</p>
            }
            <div style={{ margin: "0.5rem 0", textAlign: "center" }}>
                <span className="k-icon k-i-loading" style={{ fontSize: "2rem" }}></span>
            </div>
        </Dialog>}
    </>
    );
}
