import { useContext, useState } from "react";
import { DonationPageStateContext } from "../state";
import { ActionType } from "../actions";
import "./split-it.css";

export const SplitIt = () => {
    const { state, dispatch } = useContext(DonationPageStateContext);
    const [amount, setAmount] = useState("");
    const splitIt = state.configuration.slate.splitIt;
    const handleSplitIt = (e: any) => {
        e.preventDefault();
        dispatch({ type: ActionType.SplitAmount, amount: parseFloat(amount), weighted: false });
        setAmount("");
    }

    const handleAmountChange = (e: any) => {
        if (e.target.value.length <= 9) {
            setAmount(e.target.value);
        } else {
            e.preventDefault();
        }
    }

    const splitButton = <button type="button" onClick={handleSplitIt}>{splitIt.button}</button>

    if (!splitIt.enabled) {
        return <></>;
    } else {
        return (
            <section className="split-it">
                <header>
                    <h1>{splitIt.title}</h1>
                    <p>{splitIt.content}</p>
                </header>
                <div className="currency-input">
                    <label>$</label>
                    <input type="number" step="any" min="1" id="split-amount" maxLength={9} onChange={handleAmountChange} value={amount || ""} />
                </div>
                {splitButton}
            </section>
        );
    }
}
