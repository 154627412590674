import React, { useContext, useMemo } from "react";
import { RecipientContext, DonationPageStateContext, DonationPageState } from "../state";
import "./recipient-goal.css";

export const RecipientGoal = () => {
    const { state } = useContext(DonationPageStateContext);
    const recipient = useContext(RecipientContext);

    if (!state.configuration.slate.goal.enabled || !recipient.goal) {
        return <></>;
    } else {
        const progressBarPercent = `${Math.round(recipient.goal.percent)}%`

        return (
            <div className="recipient-goal">
                <div className="goal-info">
                    <div>Fundraising progress to goal ({progressBarPercent})</div>
                </div>
                <div className="goal-bar">
                    <div className="goal-progress" style={{ width: progressBarPercent }}>&nbsp;</div>
                </div>
            </div>
        );
    }
}
