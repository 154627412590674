import React, { useContext, useMemo } from "react";
import { RecipientCard } from "./recipient-card";
import { DonationPageStateContext, getFilteredRecipients } from "../state";
import "./recipient-card-list.css";
import { filter } from "lodash";

export const RecipientCardList = () => {
    const { state } = useContext(DonationPageStateContext);
    const slateConfig = state.configuration.slate;
    const layoutClass = slateConfig.recipientTemplate.layout.toLowerCase();
    const filteredRecipients = getFilteredRecipients(state);
    const singleClass = filteredRecipients.length == 1 ? "single" : "";

    const recipientCards = filteredRecipients.map(recipient =>
        <RecipientCard recipient={recipient} key={recipient.identifier} />
    );

    const noRecipients = filteredRecipients.length == 0;
    if (noRecipients) {
        return <p className="no-recipients">No Matches</p>;
    } else {
        return (<>
            <ul className={`recipient-card-list ${layoutClass} ${singleClass}`}>
                {recipientCards}
            </ul>
        </>);
    }
}
