import React, { useContext, useMemo } from "react";
import { ActionType } from "../actions";
import { DonationPageStateContext, RecipientContext } from "../state";
import { shouldShowProfileDialog } from "./recipient-helper";
import "./recipient-photo.css";

export const RecipientPhoto = ({ disableDialog = false }: { disableDialog?: boolean}) => {
    const { state } = useContext(DonationPageStateContext);
    const { dispatch } = useContext(DonationPageStateContext);
    const recipient = useContext(RecipientContext);
    const pictureClass = disableDialog ? "" : "clickable";
    const slateConfig = state.configuration.slate;

    let showDialog = shouldShowProfileDialog(recipient, slateConfig) && !disableDialog;
    let showDialogLink = showDialog && !slateConfig.recipientTemplate.enableProfileTeaser;

    const dialogHandler = () => {
        dispatch({ type: ActionType.ShowRecipientDialog, recipientId: recipient.identifier });
    }

    const showDialogHandler = showDialog ? dialogHandler : undefined;

    return (
        <picture className={`${pictureClass} recipient-photo`} onClick={showDialogHandler}>
            <img src={`/recipient/${recipient.identifier}/photo`} loading="lazy" alt="Photo" className="photo" />
            {showDialog && showDialogLink && <p className="link">{ slateConfig.recipientDialog.showDialogLabel }</p>}
        </picture>
    );
}
