import { Recipient, SlateConfiguration } from "../state";

export const shouldShowProfileDialog = (recipient: Recipient, slate: SlateConfiguration) => {
    // Only show the dialog if the recipient has a description or link

    let showDialog = false;

    if (slate.recipientDialog.enabled) {
        showDialog = recipient.description
            || recipient.links.facebook
            || recipient.links.instragram
            || recipient.links.site
            || recipient.links.twitter
            || recipient.links.youTube ? true : false;
    }

    return showDialog;
}