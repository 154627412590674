import { useContext, useEffect, useState } from "react";
import { ActionType } from "../actions";
import { DonationPageStateContext, DonorType } from "../state";
import "./pac-donation-toggle.css";

export const PacDonationToggle = () => {
    const { state, dispatch } = useContext(DonationPageStateContext);

    const toggleDonorType = (donorType: DonorType) => {
        dispatch({ type: ActionType.UpdateDonorType, donorType: donorType })
    }

    return (
        <section className="pac-donation-toggle">
            <label>Making donation as:</label>
            <label><input type="radio" name="donortype" value="Individual" checked={state.donorType == "Individual"} onChange={e => toggleDonorType("Individual")} />Individual Person</label>
            <label><input type="radio" name="donortype" value="FederalPac" checked={state.donorType == "FederalPac"} onChange={e => toggleDonorType("FederalPac")} />Federal PAC</label>
        </section>
    );
}
