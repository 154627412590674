import * as React from "react";

import { useFieldFormContext, ErrorMessage } from "../../common";

export const PaymentElectronicCheck = () => {
    const { registerField } = useFieldFormContext();

    const fields = {
        accountType: {
            id: "accountType",
            name: "payment.electronicCheck.accountType",
            options: {
                required: true
            }
        },
        accountNumber: {
            id: "accountNumber",
            name: "payment.electronicCheck.accountNumber",
            options: {
                required: { value: true, message: "Enter an account number" },
            }
        },
        routingNumber: {
            id: "routingNumber",
            name: "payment.electronicCheck.routingNumber",
            options: {
                required: { value: true, message: "Enter a routing number" },
            }
        }
    }

    return (
        <>
            <div className="form-group account-type">
                <label htmlFor={fields.accountType.id}>Account Type</label>
                <select {...registerField(fields.accountType)}>
                    <option value="Checking">Checking</option>
                    <option value="Checking">Savings</option>
                </select>
            </div>
            <div className="form-group account-number">
                <label htmlFor={fields.accountNumber.id}>Account Number</label>
                <input type="text" {...registerField(fields.accountNumber)} />
                <ErrorMessage name={fields.accountNumber.name} />
            </div>
            <div className="form-group routing-number">
                <label htmlFor={fields.routingNumber.id}>Routing Number</label>
                <input type="text" {...registerField(fields.routingNumber)} />
                <ErrorMessage name={fields.routingNumber.name} />
            </div>
        </>
    );
}