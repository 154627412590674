import * as React from "react";
import { useContext } from "react";
import { useFieldFormContext, ErrorMessage } from "../../common";
import { DonationPageStateContext } from "../state";
import "./custom-checkbox.scss";

export const CustomCheckbox = () => {

    const { registerField, register } = useFieldFormContext();
    const { state: donationPageState } = useContext(DonationPageStateContext);

    const fields = {
        customCheckbox: {
            id: "customCheckbox",
            name: "paymentCheckbox.checked",
            options: {
                value: donationPageState.configuration.checkout.customCheckbox.checked,
                required: { value: donationPageState.configuration.checkout.customCheckbox.required, message: "Checkbox is required" }
            }
        },
        customCheckboxName: {
            id: "customCheckbox",
            name: "paymentCheckbox.name"
        }
    }

    const options = donationPageState.configuration.checkout.customCheckbox;

    const htmlInContent = (options.content || "").indexOf('>') > -1 || false;

    const labelContent = htmlInContent ? <span dangerouslySetInnerHTML={{ __html: options.content || "" }}></span> : options.content;

    return (
        <fieldset className="custom-checkbox">
            <legend>{options.title}</legend>
            <div className="form-group">
                <label htmlFor={fields.customCheckbox.id}>
                    <input type="checkbox" {...registerField(fields.customCheckbox)}></input>
                    {labelContent}
                </label>
                <input type="hidden" {...register("paymentCheckbox.name", { value: donationPageState.configuration.checkout.customCheckbox.name })} />
                <ErrorMessage name={fields.customCheckbox.name} />
            </div>
        </fieldset>
    );
}