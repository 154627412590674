import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CurrencyFormatter } from "../common/currency";
import { CheckoutForm, DonationPageStateContext } from "../state";
import "./donor-covered-fees.scss";

export const DonorCoveredFees = () => {
    const { state } = useContext(DonationPageStateContext);
    const { register, formState: { errors } } = useFormContext<CheckoutForm>();
    const config = state.configuration.checkout.donorCoveredFees;

    const feesAmount = state.cartAmounts.donorCoveredFees;

    return (
        <fieldset className="donor-covered-fees callout">
            <legend>{config.title}</legend>
            {config.content && <p>{config.content}</p>}
            <div className="form-group">
                <label htmlFor="donorCoveredFees" className="checkbox">
                    <input type="checkbox" id="donorCoveredFees" {...register("donorCoveredFees.enabled")}></input>
                    {config.checkboxLabel} ({CurrencyFormatter.format(feesAmount)})
                </label>
            </div>
        </fieldset>
    );
}
