import ReactDOM from "react-dom";
import React, { useState } from "react";
import { useEffect, useReducer } from "react";
import { Loader } from "@progress/kendo-react-indicators";
import { DonationPageStateContext, initialDonationPageState, QueryRecipient } from "./state";
import { Checkout } from "./checkout/checkout";
import { Confirmation } from "./confirmation/confirmation";
import { Slate } from "./slate/slate";
import { useFetch } from "../common";

import {
    Router,
    Route,
    Outlet,
    ReactLocation
  } from "react-location";

import { donationPageReducer } from "./reducers";
import { ActionType } from "./actions";

import "@progress/kendo-theme-default/dist/all.css";
import "./donation-page.scss";

const routes: Route[] = [
    {
        path: "/",
        element: <Slate />,
    },
    {
        path: "checkout",
        element: <Checkout />,
    },
    {
        path: "confirmation",
        element: <Confirmation />,
    }
];

const location = new ReactLocation();

export const DonationPage = () => {

    const [state, dispatch] = useReducer(donationPageReducer, initialDonationPageState);
    const searchParams = new URLSearchParams(window.location.search);

    // Build the API url
    const automaticRecipientPage = window.location.pathname.indexOf("/recipient/") > -1;
    const urlSlug = window.location.pathname.split('/').filter(x => x != '' && x != 'checkout').pop() || "";
    let dataApiUrl = automaticRecipientPage ? `/api/v2/recipient-page/${urlSlug}` : `/api/v2/donation-page/${urlSlug}`;

    if (state.donorType == "FederalPac") {
        dataApiUrl = dataApiUrl + "?donorType=FederalPac";
    } else if (state.donorType == "Individual") {
        dataApiUrl = dataApiUrl + "?donorType=Individual";
    }

    const dataApi = useFetch(dataApiUrl);
    const currentUserApi = useFetch(`/api/v2/me`);

    // Fetch the current user function
    const fetchCurrentUser = async () => {
        const currentUserResponse = await currentUserApi.get();

        // If there is no user, a 204 will be returned. Only process if a 200 is returned.
        if (currentUserResponse.status == 200) {
            dispatch({ type: ActionType.LoadCurrentUser, currentUser: currentUserResponse.result })
        }
    }

    // Fetch the page's data function
    const fetchPageData = async () => {
        // Check query string for recipients
        const queryStringRecipients = searchParams.get("recipients");
        let queryRecipients: QueryRecipient[] = [];
        if (queryStringRecipients) {
            const kvps = queryStringRecipients.split(";");
            const qrs: QueryRecipient[] = kvps.map(kvp => {
                const parts = kvp.split(":");
                return {
                    identifier: parts[0],
                    amount: parts.length > 1 ? parseFloat(parts[1]) : 0
                }
            });
            queryRecipients = queryRecipients.concat(qrs);
        }

        const pageDataResponse = await dataApi.get()

        if (pageDataResponse.success) {
            if (pageDataResponse.result.configuration.slate.header.title) {
                document.title = pageDataResponse.result.configuration.slate.header.title + " " + document.title;
            } else if (pageDataResponse.result.recipients.length == 1) {
                document.title = pageDataResponse.result.recipients[0].displayName + " " + document.title;
            }
            dispatch({ type: ActionType.LoadData, data: pageDataResponse.result, queryRecipients: queryRecipients, urlSlug: urlSlug })
        } else if (pageDataResponse.status == 404) {
            dispatch({ type: ActionType.PageNotFound })
        }
    }

    // Effects
    useEffect(() => {
        if (state.donorTypeUpdated) {
            fetchCurrentUser().catch(console.error);
            fetchPageData().catch(console.error);
        }
    }, [state.donorType]);

    useEffect(() => {
        fetchCurrentUser().catch(console.error);
        fetchPageData().catch(console.error);
    }, []);

    if (state.configuration.status == "NotFound") {
        return <header><h1>Page Not Found</h1></header>;
    } else if (state.configuration.status == "Loading") {
        return <header className="loading"><Loader size="medium" themeColor="dark" type="infinite-spinner" /></header>;
    }

    // The base is the root donation page, without any sub-path like "checkout"
    const basepath = window.location.pathname.replace("checkout", "");

    // Render
    return (
        <Router routes={routes} location={location} basepath={basepath}>
            <DonationPageStateContext.Provider value={{ state: state, dispatch }}>
                <Outlet />
            </DonationPageStateContext.Provider>
        </Router>
    );
}

ReactDOM.render(
    <React.StrictMode>
      <DonationPage />
    </React.StrictMode>,
    document.getElementById("root"))