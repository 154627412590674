import { useContext, useState } from "react";
import { DonationPageStateContext } from "../state";
import { ActionType } from "../actions";
import "./weighted-split-it.css";

export const WeightedSplitIt = () => {
    const { state, dispatch } = useContext(DonationPageStateContext);
    const [amount, setAmount] = useState("");
    const config = state.configuration.slate.weightedSplitIt;
    const handleSplitIt = (e: any) => {
        e.preventDefault();
        dispatch({ type: ActionType.SplitAmount, amount: parseFloat(amount), weighted: true });
        setAmount("");
    }

    const handleAmountChange = (e: any) => {
        if (e.target.value.length <= 9) {
            setAmount(e.target.value);
        } else {
            e.preventDefault();
        }
    }

    const splitButton = <button type="button" onClick={handleSplitIt}>{config.button}</button>

    if (!config.enabled) {
        return <></>;
    } else {
        return (
            <section className="weighted-split-it">
                <header>
                    <h1>{config.title}</h1>
                    <p>{config.content}</p>
                </header>
                <div className="currency-input">
                    <label>$</label>
                    <input type="number" step="any" min="1" max="9999999" id="split-amount" maxLength={9} onChange={handleAmountChange} value={amount || ""} />
                </div>
                {splitButton}
            </section>
        );
    }
}
