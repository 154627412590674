import { useContext } from "react";
import { CurrencyFormatter } from "../common/currency";
import { PageHeader } from "../common/page-header";
import { DonationPageStateContext } from "../state";

import "./confirmation.scss";

export const Confirmation = () => {
    const { state } = useContext(DonationPageStateContext);

    const confirmationConfig = state.configuration.confirmation;

    const amounts = state.cartAmounts;
    const coveredFeesAmount = state.checkoutForm.donorCoveredFees.enabled ? amounts.donorCoveredFees : 0;
    const mandatoryFeeAmount = amounts.mandatoryFee;
    const totalAmount = amounts.total;
    const tipAmount = amounts.tip;

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const currentDate = (): string => {
        const date = new Date();

        const monthName = monthNames[date.getMonth()];
        let day = date.getDate().toString();
        if (day.length < 2) {
            day = `0${day}`;
        }

        return `${monthName} ${day}, ${date.getFullYear()}`;
    }

    const lineItemRows = state.recipients
        .filter(r => r.donationAmount.amount > 0)
        .map(r => {
            return (<tr key={r.identifier}>
                <td>{r.displayName} ({r.legalName})</td>
                <td>{CurrencyFormatter.format(r.donationAmount.amount)}</td>
            </tr>)
        });

    if (tipAmount) {
        lineItemRows.push(<tr key="tip"><td>{state.configuration.checkout.tip.recipient?.displayName}</td><td>{CurrencyFormatter.format(tipAmount)}</td></tr>);
    }

    if (coveredFeesAmount) {
        lineItemRows.push(<tr key="processing-fees"><td>Processing Fees</td><td>{CurrencyFormatter.format(coveredFeesAmount)}</td></tr>);
    }

    if (mandatoryFeeAmount) {
        lineItemRows.push(<tr key="mandatory-fees"><td>{state.configuration.checkout.mandatoryFee.lineItemText}</td><td>{CurrencyFormatter.format(mandatoryFeeAmount)}</td></tr>);
    }

    return (
        <>
            <PageHeader config={confirmationConfig.header} pageClassName="confirmation-page" />
            <div className="confirmation content">
                <div className="confirmation-details">
                    <header>
                        <span className="total">{CurrencyFormatter.format(totalAmount)}</span> <time>{currentDate()}</time>
                    </header>
                    <table>
                        <tbody>
                            {lineItemRows}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
