import React, { useContext } from "react";
import { RecipientContext } from "../state";
import "./recipient-photo.css";

export const RecipientCustomFields = () => {
    const recipient = useContext(RecipientContext);

    const customFields = [];

    if (recipient.customField1) {
        customFields.push(<div className="custom-field-1" key="custom-field-1">{recipient.customField1}</div>);
    }

    if (recipient.customField2) {
        customFields.push(<div className="custom-field-2" key="custom-field-2">{recipient.customField2}</div>);
    }

    if (customFields.length > 0) {
        return (
            <div className="custom-fields">
                {customFields}
            </div>
        );
    } else {
        return <></>;
    }
}
