import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import * as React from "react";
import { useContext } from "react";
import { ActionType } from "../actions";
import { DonationPageStateContext } from "../state";
import "./max-out.css";

export const MaxOut = () => {
    const { state, dispatch } = useContext(DonationPageStateContext);
    const [input, setInput] = React.useState(0);

    const maxOut = state.configuration.slate.maxOut;
    const thresholdAmountDisplay = new Intl
                                        .NumberFormat("en-US", { style: "currency", currency: "USD" })
                                        .format(maxOut.thresholdAmount)
                                        .replace(".00", "");

    if (!maxOut.enabled) {
        return <></>;
    } else {
        return (
            <>
                <section className="max-out">
                    <header>
                        <h1>{maxOut.title}</h1>
                        <p>{maxOut.content}</p>
                    </header>
                    <button type="button" onClick={() => dispatch({ type: ActionType.MaxOut })}>{maxOut.button}</button>
                </section>
                { state.maxOut.showDialog &&
                    <Dialog className="max-out-dialog"
                        title='Confirm Maximum Donation'
                        width="400px"
                        onClose={() => dispatch({ type: ActionType.CancelMaxOut }) }>
                        <p>One or more recipients on this page have no maximum donation limit or a limit above {thresholdAmountDisplay}. Please enter the amount you wish to donate to each of these recipients. <br /><br />Please note: the amount you enter will only be applied to recipients above the threshold, all other recipients on the page will receive their maximum donation (even if what you enter in this box is a lower amount).</p>
                        <p>
                            <div className="currency-input">
                                <label>$</label>
                                <input type="number" step="any" min="1" id="split-amount" maxLength={6} onChange={e => setInput(parseFloat(e.target.value))} />
                            </div>
                        </p>
                        <DialogActionsBar>
                            <button disabled={input <= 0} onClick={() => dispatch({ type: ActionType.MaxOut, amount: input })}>OK</button>
                            <button onClick={() => dispatch({ type: ActionType.CancelMaxOut })}>Cancel</button>
                        </DialogActionsBar>
                    </Dialog> }
            </>
        );
    }
}
