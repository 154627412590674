import { useContext } from "react";
import { DonationPageStateContext } from "../state";
import { Terms } from "../common/terms";
import { RecipientCardList } from "./recipient-card-list";
import { SingleRecipientCard } from "./single-recipient-card";
import { SlateCart } from "./slate-cart";
import { SplitIt } from "./split-it";
import { WeightedSplitIt } from "./weighted-split-it";
import { MaxOut } from "./max-out";
import { FeaturedToggle } from "./featured-toggle";
import { SlateFilters } from "./slate-filters";
import { RecipientDialog } from "./recipient-dialog";
import { PageHeader } from "../common/page-header";
import { ProcessingIndicator } from "../common/processing-indicator";
import { NotificationBanner } from "./notification-banner";
import { PacDonationToggle } from "./pac-donation-toggle";
import "./slate.css";

export const Slate = () => {
    const { state } = useContext(DonationPageStateContext);
    const isSingleRecipient = state.recipients.length == 1;
    const slateConfig = state.configuration.slate;
    const context = state.configuration.context;
    const recipientsLayout = isSingleRecipient
        ? <>
            {state.configuration.pacDonation.enabled && <PacDonationToggle />}
            <SingleRecipientCard recipient={state.recipients[0]} showDonation={true} showDescription={context == "RecipientPage"} />
        </>
        : <div className="slate-recipients">
            {state.configuration.pacDonation.enabled && <PacDonationToggle />}
            <FeaturedToggle />
            <SlateFilters />
            <RecipientCardList />
        </div>;
    const slateLayoutClasses = isSingleRecipient ? "slate single content" : "slate multiple content";
    const tools = isSingleRecipient ? <><SlateCart /> <div className="slate-allocation-tools"><MaxOut /></div></>
                                    : <><SlateCart />  <div className="slate-allocation-tools"><SplitIt /> <WeightedSplitIt /> <MaxOut /></div></>


    const terms = state.currentUser?.paymentAuthorizationSaved ? <div className="terms"><Terms terms={state.terms} showDeTerms={true} conditionalDonatingConfig={state.configuration.conditionalDonating} /></div>: <section className="terms"></section>;

    return (
        <>
            <PageHeader config={slateConfig.header} pageClassName="slate-page" />

            <div className={slateLayoutClasses}>
                <NotificationBanner />
                {recipientsLayout}
                <section className="slate-toolbar">
                    {tools}
                </section>
                {terms}
                <RecipientDialog />
                <ProcessingIndicator />
            </div>

            <footer dangerouslySetInnerHTML={{ __html: slateConfig.footerHtml }}></footer>
        </>
    );
}
