import React, { useMemo } from "react";
import { RecipientGoal } from "./recipient-goal";
import { RecipientDonation } from "./recipient-donation";
import { RecipientDescription } from "./recipient-description";
import { RecipientPhoto } from "./recipient-photo";
import { RecipientInfo } from "./recipient-info";
import { RecipientFeaturedBanner } from "./recipient-featured-banner";
import { RecipientCustomFields } from "./recipient-custom-fields";
import { Recipient, RecipientContext } from "../state";
import "./single-recipient-card.scss";

export const SingleRecipientCard = ({ recipient, showDescription, showDonation, disableDialog = false }: { recipient: Recipient, showDescription: boolean, showDonation: boolean, disableDialog?: boolean }) => {
    return useMemo(() => {
        const partyClass = recipient.politicalParty?.toLowerCase() || "";
        const customField1Class = recipient.customField1?.toLocaleLowerCase().replaceAll(' ', '-') || "";
        const customField2Class = recipient.customField2?.toLocaleLowerCase().replaceAll(' ', '-') || "";
        const featuredClass = recipient.featured ? "featured" : "";

        const classes = [];
        if (partyClass) {
            classes.push(partyClass);
        }

        if (customField1Class) {
            classes.push(customField1Class);
        }

        if (customField2Class) {
            classes.push(customField2Class);
        }

        if (featuredClass) {
            classes.push(featuredClass);
        }

        return (
            <RecipientContext.Provider value={{ ...recipient }}>
                <div className={`single-recipient-card ${classes.join(" ")}`}>
                    <RecipientFeaturedBanner />
                    <RecipientPhoto disableDialog={disableDialog} />
                    <RecipientInfo disableDialog={disableDialog} />
                    <RecipientGoal />
                    <RecipientDescription show={showDescription} />
                    {showDonation && <RecipientDonation />}
                    <RecipientCustomFields />
                    {recipient.disclaimer && <div className="recipient-disclaimer" dangerouslySetInnerHTML={{ __html: recipient.disclaimer }}></div>}
                </div>
            </RecipientContext.Provider>

        );
    }, [recipient]);
}
