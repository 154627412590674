import { createContext, Dispatch } from "react";
import { ApiError } from "../common";
import { Action } from "./actions";

// STATE
export class SuggestedAmount {
    constructor(public readonly amount: number) {
        this.kind = "suggested";
    }

    readonly kind: string;
}

export class OtherAmount {
    constructor(public amt: number, public amtStr: string = "") {
        this.kind = "other";

        if (amtStr) {
            const decimalIndex = amtStr.indexOf(".");
            if (decimalIndex > -1) {
                // Don't allow more than 2 decimal points
                this.amountString = amtStr.substr(0, decimalIndex + 3);
                this.amount = parseFloat(this.amountString);
            } else {
                this.amountString = amtStr;
                this.amount = Math.round(amt * 100) / 100;
            }
        } else {
            this.amount = Math.round(amt * 100) / 100;
            this.amountString = amt.toFixed(2);
        }
    }

    readonly amount: number;
    readonly amountString: string;
    readonly kind: string;
}

export type DonationAmount = SuggestedAmount | OtherAmount;
export type DonorType = "Default" | "Individual" | "FederalPac";

export interface DonationPageState {
    apiErrors: ApiError[];
    cartAmounts: CartAmounts;
    checkoutForm: CheckoutForm;
    configuration: {
        checkout: CheckoutConfiguration,
        slate: SlateConfiguration,
        conditionalDonating: ConditionalDonatingConfiguration,
        confirmation: ConfirmationConfiguration,
        earmarking: EarmarkingConfiguration,
        event: EventConfiguration,
        donationLimit: DonationLimitConfiguration,
        pacDonation: PacDonationConfiguration,
        status: "Loading" | "NotFound" | "Published" | "Unpublished" | "Closed" | "Archived" | "ReadyForReview",
        context: "StandardPage" | "RecipientPage",
        pagePermissionLevel: "Everyone" | "Authenticated" | "VIP" | "Administrator"
    };
    createSubscription: boolean;
    currentUser?: CurrentUser;
    donorType: DonorType;
    donorTypeUpdated: boolean;
    filters: Filters;
    donationLimit: DonationLimit;
    maxOut: {
        showDialog: boolean;
    };
    processingIndicator: ProcessingIndicator;
    recipients: Recipient[];
    recipientDialog: RecipientDialog;
    sourceCode: string;
    utmTracking: {
        campaign: string;
        content: string;
        medium: string;
        source: string;
        term: string;
    }
    terms: Term[];
    urlSlug: string;
}

export interface DonationLimit {
    limitReached: boolean;
    donatedAmount: number;
}

export interface CartAmounts {
    base: number;
    total: number;
    mandatoryFee: number;
    donorCoveredFees: number;
    tip: number;
}

export interface ConditionalDonatingConfiguration {
    enabled: boolean;
    conditionContent: string;
}

export interface EarmarkingConfiguration {
    enabled: boolean;
}

export interface DonationLimitConfiguration {
    enabled: boolean;
    limit: number;
    remaining: number;
    donationTotal: number;
}

export interface PacDonationConfiguration {
    enabled: boolean;
    defaultDonorType: DonorType;
}

export interface SlateConfiguration {
    featuredRecipients: {
        enabled: boolean;
        defaultFilter: "All" | "Featured";
        viewAllText: string;
        viewFeaturedText: string;
        bannerText: string;
    }
    filters: {
        enabled: boolean;
        customField1: CustomFieldFilter;
        customField2: CustomFieldFilter;
        displayName: boolean;
        office: boolean;
        state: boolean;
        politicalParty: boolean;
    }
    goal: {
        enabled: boolean;
    }
    footerHtml: string;
    header: Header
    maxOut: {
        enabled: boolean;
        title: string;
        content: string;
        button: string;
        thresholdAmount: number;
    }
    recipientTemplate: {
        enableProfileTeaser: boolean;
        infoTemplate: string;
        layout: string;
    }
    recipientDialog: {
        enabled: boolean;
        showDialogLabel: string;
        extendedProfileTitle: string;
    }
    splitIt: {
        enabled: boolean;
        title: string;
        content: string;
        button: string;
    }
    weightedSplitIt: {
        enabled: boolean;
        title: string;
        content: string;
        button: string;
    }
    subscription: {
        enabled: boolean;
    }
    suggestedDonationAmounts: number[];
}

export interface AttributionList {
    id: string,
    members: string[]
}

export interface CheckoutConfiguration {
    header: Header,
    donationAttribution: {
        attributionLists: AttributionList[];
        content: string;
        enabled: boolean;
        required: boolean;
        title: string;
    },
    donorCoveredFees: {
        enabled: boolean;
        title: string;
        checkboxLabel: string;
        content: string;
        creditCardBase: number;
        creditCardRate: number;
        electronicCheckBase: number;
        electronicCheckRate: number;
    },
    tip: {
        content: string,
        enabled: boolean,
        suggestedAmounts: number[],
        title: string,
        recipient?: TipRecipient | null
    },
    customCheckbox: {
        checked: boolean,
        content: string | null,
        enabled: boolean,
        name: string | null,
        required: boolean,
        title: string | null
    },
    emailNote: string | null,
    employmentNote: string | null,
    textMessagingOptIn: {
        enabled: boolean,
        label: string | null
    },
    mandatoryFee: {
        enabled: boolean,
        baseAmount: number,
        rate: number,
        maximumAmount: number | null,
        title: string,
        content: string,
        lineItemText: string
    }
}

export interface CurrentUser {
    address: string;
    city: string;
    displayName: string;
    email: string;
    employer: string;
    employerAddress: string;
    employerCity: string;
    employerPostalCode: string;
    employerState: string;
    firstName: string;
    id: string;
    lastName: string;
    occupation: string;
    paymentAuthorizationDisplayName: string;
    paymentAuthorizationSaved: boolean;
    phoneNumber: string;
    postalCode: string;
    state: string;
}

export interface ConfirmationConfiguration {
    header: Header;
}

export interface EventConfiguration {
    enabled: boolean;
}

export interface QueryRecipient {
    identifier: string;
    amount?: number;
}

export interface Header {
    title: string;
    content: string;
    html: string;
}

export interface Term {
    id: string;
    content: string;
}

export interface RecipientDialog {
    recipientId?: string | null;
    show: boolean;
}

export interface ProcessingIndicator {
    message?: string;
    show: boolean;
}

export interface Filters {
    featured: boolean;
    displayName: string;
    states: string[];
    offices: string[];
    politicalParties: string[];
    customField1: string[];
    customField2: string[];
}

export interface CustomFieldFilter {
    enabled: boolean;
    label: string;
}

export interface TipRecipient {
    contributionLimit: number;
    identifier: string;
    displayName: string;
    termIds: string[];
}

export interface RecipientLinks {
    facebook?: string | null;
    instragram?: string | null;
    site?: string | null;
    twitter?: string | null;
    youTube?: string | null;
}

export interface RecipientProfileField {
    name: string;
    order: number;
    value: string;
}

export interface Recipient {
    attribution: string;
    attributionListId: string;
    customField1: string;
    customField2: string;
    cycle: string;
    displayName: string;
    extendedProfileFields: RecipientProfileField[];
    earmarked: boolean;
    identifier: string;
    state: string;
    district: string;
    disclaimer: string;
    links: RecipientLinks;
    office: string;
    politicalParty: string;
    description: string;
    contributionLimit: number;
    donationAmount: DonationAmount;
    weight: number;
    featured: boolean;
    order: number;
    profileTeaser: string;
    requireEmployerAddress: boolean;
    recipientType: string;
    termIds: string[];
    goal: {
        achieved: boolean,
        amount: number,
        percent: number,
        progress: number,
        remaining: number
    },
    legalName: string;
    pausedStatus: {
        paused: boolean,
        message: string
    };
}

export interface CheckoutForm {
    attributionFields: string[];
    contact: {
        firstName: string;
        lastName: string;
        streetAddress: string;
        city: string;
        state: string;
        postalCode: string;
        email: string;
        phoneNumber: string;
        textMessagingOptIn: boolean;
    };
    employment: {
        status: "Employed" | "Retired" | "NotEmployed"
        employer: string;
        occupation: string
        streetAddress: string;
        city: string;
        state: string;
        postalCode: string;
    };
    donorCoveredFees: {
        enabled: boolean;
        amount?: number;
    };
    eventAttendance: {
        attending: boolean;
        numberOfAttendees: number;
    };
    payment: {
        paymentMethod: "CreditCard" | "ElectronicCheck";
        useStoredPaymentAuthorization: boolean;
        rememberPayment: boolean;
        password: string;
        confirmPassword: string;
        creditCard: {
            cardNumber: string;
            expirationMonth: string;
            expirationYear: string;
            securityCode: string;
        } | null;
        electronicCheck: {
            accountType: "Checking" | "Savings";
            accountNumber: string;
            routingNumber: string;
        } | null;
        paymentCheckbox: boolean;
    },
    tip: {
        amount: number;
    }
}

// INITIAL STATE
const getArrayFromQueryString = (field: string): string[] => {
    const value = new URLSearchParams(window.location.search).get(field);
    if (!value) {
        return [];
    }
    return value.split(",");
}

const initialFilters = {
    featured: true,
    displayName: "",
    states: getArrayFromQueryString("state"),
    offices: getArrayFromQueryString("office"),
    politicalParties: getArrayFromQueryString("party"),
    customField1: [],
    customField2: []
}

const initialCheckoutForm: CheckoutForm = {
    attributionFields: [],
    contact: {
        firstName: "",
        lastName: "",
        streetAddress: "",
        city: "",
        state: "",
        postalCode: "",
        email: "",
        phoneNumber: "",
        textMessagingOptIn: false
    },
    employment: {
        status: "Employed",
        employer: "",
        streetAddress: "",
        city: "",
        state: "",
        postalCode: "",
        occupation: ""
    },
    donorCoveredFees: {
        enabled: false,
        amount: 0
    },
    eventAttendance: {
        attending: true,
        numberOfAttendees: 1
    },
    payment: {
        paymentMethod: "CreditCard",
        useStoredPaymentAuthorization: false,
        rememberPayment: false,
        password: "",
        confirmPassword: "",
        creditCard: {
            cardNumber: "",
            expirationMonth: "",
            expirationYear: "",
            securityCode: ""
        },
        electronicCheck: {
            accountType: "Checking",
            accountNumber: "",
            routingNumber: ""
        },
        paymentCheckbox: false
    },
    tip: {
        amount: 0
    }
}

const initialCheckoutConfiguration = {
    header: {
        title: "",
        content: "",
        html: ""
    },
    donationAttribution: {
        enabled: false,
        required: false,
        content: "",
        title: "",
        attributionLists: []
    },
    donorCoveredFees: {
        enabled: false,
        title: "",
        checkboxLabel: "",
        content: "",
        creditCardBase: 0,
        creditCardRate: 0,
        electronicCheckBase: 0,
        electronicCheckRate: 0
    },
    tip: {
        content: "",
        enabled: false,
        suggestedAmounts: [],
        title: "",
        recipient: null
    },
    customCheckbox: {
        checked: false,
        content: "",
        enabled: false,
        name: "",
        required: true,
        title: ""
    },
    emailNote: "",
    employmentNote: "",
    textMessagingOptIn: {
        enabled: false,
        label: ""
    },
    terms: [],
    mandatoryFee: {
        enabled: false,
        baseAmount: 0,
        rate: 0,
        maximumAmount: null,
        title: "",
        content: "",
        lineItemText: ""
    }
}

const initialConditionalDonatingConfiguration = {
    enabled: false,
    conditionContent: ""
}

const initialEventConfiguration = {
    enabled: false
}

const initialPacDonationConfiguration: PacDonationConfiguration = {
    enabled: false,
    defaultDonorType: "Default"
}

const initialSlateConfiguration = {
    featuredRecipients: {
        enabled: true,
        defaultFilter: <"All" | "Featured">"Featured",
        viewAllText: "",
        viewFeaturedText: "",
        bannerText: ""
    },
    filters: {
        enabled: false,
        customField1: {
            enabled: false,
            label: ""
        },
        customField2: {
            enabled: false,
            label: ""
        },
        displayName: false,
        office: false,
        state: false,
        politicalParty: false
    },
    footerHtml: "",
    goal: {
        enabled: false
    },
    header: {
        title: "",
        content: "",
        html: ""
    },
    maxOut: {
        enabled: false,
        title: "",
        content: "",
        button: "",
        thresholdAmount: 0
    },
    recipientDialog: {
        enabled: false,
        showDialogLabel: "",
        extendedProfileTitle: ""
    },
    recipientTemplate: {
        enableProfileTeaser: false,
        enableGoal: false,
        infoTemplate: "",
        layout: ""
    },
    splitIt: {
        enabled: false,
        title: "",
        content: "",
        button: ""
    },
    weightedSplitIt: {
        enabled: false,
        title: "",
        content: "",
        button: ""
    },
    subscription: {
        enabled: false
    },
    suggestedDonationAmounts: [10, 25, 50, 100, 250],

    detailsTemplate: ""
}

export const initialDonationPageState: DonationPageState = {
    configuration: {
        checkout: initialCheckoutConfiguration,
        context: "StandardPage",
        conditionalDonating: initialConditionalDonatingConfiguration,
        donationLimit: {
            enabled: false,
            limit: 0,
            remaining: 0,
            donationTotal: 0
        },
        earmarking: {
            enabled: false
        },
        event: initialEventConfiguration,
        pacDonation: initialPacDonationConfiguration,
        slate: initialSlateConfiguration,
        status: "Loading",
        confirmation: {
            header: {
                title: "",
                content: "",
                html: ""
            }
        },
        pagePermissionLevel: "Everyone"
    },
    cartAmounts: {
        base: 0,
        total: 0,
        mandatoryFee: 0,
        donorCoveredFees: 0,
        tip: 0
    },
    createSubscription: false,
    donorType: "Default",
    donorTypeUpdated: false,
    donationLimit: {
        limitReached: false,
        donatedAmount: 0
    },
    recipients: [],
    terms: [],
    filters: initialFilters,
    checkoutForm: initialCheckoutForm,
    recipientDialog: { recipientId: null, show: false },
    sourceCode: new URLSearchParams(window.location.search).get("sc") || "",
    utmTracking: {
        campaign: new URLSearchParams(window.location.search).get("utm_campaign") || "",
        content: new URLSearchParams(window.location.search).get("utm_content") || "",
        medium: new URLSearchParams(window.location.search).get("utm_medium") || "",
        source: new URLSearchParams(window.location.search).get("utm_source") || "",
        term: new URLSearchParams(window.location.search).get("utm_term") || ""
    },
    apiErrors: [],
    processingIndicator: { show: false, message: "" },
    urlSlug: "",
    maxOut: {
        showDialog: false
    }
}

export const getFilteredRecipients = (state: DonationPageState) => {
    const filters = state.filters;
    let filteredRecipients = state.recipients
        .filter(r => !filters.displayName || r.displayName.toLowerCase().indexOf(filters.displayName.toLowerCase()) != -1)
        .filter(r => filters.states.length == 0 || filters.states.indexOf(r.state) != -1)
        .filter(r => filters.offices.length == 0 || filters.offices.indexOf(r.office) != -1)
        .filter(r => filters.politicalParties.length == 0 || filters.politicalParties.indexOf(r.politicalParty) != -1)
        .filter(r => filters.customField1.length == 0 || filters.customField1.indexOf(r.customField1) != -1)
        .filter(r => filters.customField2.length == 0 || filters.customField2.indexOf(r.customField2) != -1);

    const featuredFilterEnabled = state.configuration.slate.featuredRecipients.enabled || false;

    if (featuredFilterEnabled) {
        filteredRecipients = filteredRecipients.filter(r => filters.featured ? r.featured : true)
    }

    return filteredRecipients;
}

// CONTEXTS
let initialDonationPageStateContext = { state: initialDonationPageState, dispatch: (action: Action) => { } }
export const DonationPageStateContext = createContext<{ state: DonationPageState, dispatch: Dispatch<Action> }>(initialDonationPageStateContext);

let initialRecipientContext = initialDonationPageState.recipients[0];
export const RecipientContext = createContext<Recipient>(initialRecipientContext);

