import React, { useContext } from "react";
import {
    ExpansionPanel,
    ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { DonationPageStateContext, Recipient } from "../state";
import "./extended-profile.css";

export const ExtendedProfile = ({ recipient }: { recipient: Recipient }) => {

    const { state } = useContext(DonationPageStateContext);
    const recipientDialogConfig = state.configuration.slate.recipientDialog;
    const fields = recipient.extendedProfileFields || [];
    const [expanded, setExpanded] = React.useState("");

    let expanders = fields.sort((f1, f2) => f1.order - f2.order).map(field => {
        let panel =
            <ExpansionPanel
                title={field.name}
                key={field.name}
                expanded={expanded == field.name}
                onAction={(event: any) => {
                    setExpanded(event.expanded ? "" : field.name);
                }}>
                <Reveal>
                    {expanded === field.name && (
                        <ExpansionPanelContent>
                            <div dangerouslySetInnerHTML={{ __html: field.value }}></div>
                        </ExpansionPanelContent>
                    )}
                </Reveal>
            </ExpansionPanel>

        return panel;
    });

    if (expanders.length == 0) {
        return <></>;
    }

    return <section className="extended-profile"><h1>{recipientDialogConfig.extendedProfileTitle}</h1>
                {expanders}
           </section>;
}