import { useFormContext } from "react-hook-form";
import "./event-attendance.scss";

export const EventAttendance = () => {
    const {register, watch, setValue, formState: { errors } } = useFormContext();

    const attending = watch("eventAttendance.attending", true);

    return (
        <fieldset className="event-attendance">
            <legend>RSVP</legend>
            <div className="form-group">
                <label htmlFor="attending" className="radio">
                    <input type="radio" id="attending" name="attending" value="true"
                        checked={attending}
                        onChange={() => setValue("eventAttendance.attending", true)} />I will attend the event<br />
                </label>
                <label htmlFor="notAttending" className="radio">
                    <input type="radio" id="notAttending" name="attending" value="false"
                        checked={!attending}
                        onChange={() => setValue("eventAttendance.attending", false)} />I will not attend the event, but still wish to donate
                </label>
            </div>
            {attending == true && <div className="form-group">
                <label htmlFor="numberOfAttendees">Number of attendees
                    <select id="numberOfAttendees" {...register("eventAttendance.numberOfAttendees", { valueAsNumber: true })}>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                    </select>
                </label>
            </div>}
        </fieldset>
    );
}
