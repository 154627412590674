import * as React from "react";
import { useContext } from "react";
import { useFieldFormContext, ErrorMessage } from "../../common";
import { DonationPageStateContext } from "../state";
import "./pac-contact.scss";

export const PacContact = () => {

    const { registerField, register } = useFieldFormContext();

    // Context
    const { state } = useContext(DonationPageStateContext);
    const checkoutConfiguration = state.configuration.checkout;
    const textMessagingOptions = checkoutConfiguration.textMessagingOptIn;
    const emailNote = checkoutConfiguration.emailNote;

    // Fields
    const fields = {
        pacName: {
            id: "pacName",
            name: "contact.pacName",
            options: {
                required: { value: true, message: "Enter the PAC's name" },
                maxLength: 50
            }
        },
        pacFecId: {
            id: "pacFecId",
            name: "contact.pacFecId",
            options: {
                required: { value: true, message: "Enter the PAC's FEC ID" },
                maxLength: 50
            }
        },
        firstName: {
            id: "firstName",
            name: "contact.firstName",
            options: {
                required: { value: true, message: "Enter a first name" },
                maxLength: 50,
                validate: (v:string) => v.indexOf(' and ') == -1 && v.indexOf(' & ') == -1 || 'Donations must be attributed to a single donor. Please use one first name or make two separate donations.'
            }
        },
        lastName: {
            id: "lastName",
            name: "contact.lastName",
            options: {
                required: { value: true, message: "Enter a last name" },
                maxLength: 50
            }
        },
        streetAddress: {
            id: "streetAddress",
            name: "contact.streetAddress",
            options: {
                required: { value: true, message: "Enter a street address" },
                maxLength: 50
            }
        },
        city: {
            id: "city",
            name: "contact.city",
            options: {
                required: { value: true, message: "Enter a city" },
                maxLength: 50
            }
        },
        state: {
            id: "state",
            name: "contact.state",
            options: {
                required: { value: true, message: "Select a state" },
                maxLength: 2
            }
        },
        postalCode: {
            id: "postalCode",
            name: "contact.postalCode",
            options: {
                required: { value: true, message: "Enter a ZIP code" },
                maxLength: 10
            }
        },
        email: {
            id: "email",
            name: "contact.email",
            options: {
                required: { value: true, message: "Enter an email address" },
                maxLength: 50,
                readOnly: ["VIP", "Authenticated"].includes(state.configuration.pagePermissionLevel),
                title: ["VIP", "Authenticated"].includes(state.configuration.pagePermissionLevel) ? "Email address may be updated in the Account Profile page" : null
            }
        },
        phoneNumber: {
            id: "phoneNumber",
            name: "contact.phoneNumber",
            options: {
                maxLength: 20
            }
        },
        textMessagingOptIn: {
            id: "textMessagingOptIn",
            name: "contact.textMessagingOptIn"
        }
    }

    return (
        <>
            <fieldset className="pac">
                <legend>Federal PAC Information</legend>
                <div className="form-group pac-name">
                    <label htmlFor={fields.pacName.id}>PAC Name</label>
                    <input type="text" {...registerField(fields.pacName)} />
                    <ErrorMessage name={fields.pacName.name} />
                </div>
                <div className="form-group pac-fec-id">
                    <label htmlFor={fields.pacFecId.id}>FEC ID</label>
                    <input type="text" {...registerField(fields.pacFecId)} />
                    <ErrorMessage name={fields.pacFecId.name} />
                </div>
                <div className="form-group street-address">
                    <label htmlFor={fields.streetAddress.id}>Address</label>
                    <input type="text" {...registerField(fields.streetAddress)} />
                    <ErrorMessage name={fields.streetAddress.name} />
                </div>
                <div className="form-group city">
                    <label htmlFor={fields.city.id}>City</label>
                    <input type="text" {...registerField(fields.city)} />
                    <ErrorMessage name={fields.city.name} />
                </div>
                <div className="form-group state">
                    <label htmlFor={fields.state.id}>State</label>
                    <select {...registerField(fields.state)}>
                        <option value=""></option>
                        <option value="AK">AK</option>
                        <option value="AL">AL</option>
                        <option value="AR">AR</option>
                        <option value="AZ">AZ</option>
                        <option value="CA">CA</option>
                        <option value="CO">CO</option>
                        <option value="CT">CT</option>
                        <option value="DC">DC</option>
                        <option value="DE">DE</option>
                        <option value="FL">FL</option>
                        <option value="GA">GA</option>
                        <option value="HI">HI</option>
                        <option value="IA">IA</option>
                        <option value="ID">ID</option>
                        <option value="IL">IL</option>
                        <option value="IN">IN</option>
                        <option value="KS">KS</option>
                        <option value="KY">KY</option>
                        <option value="LA">LA</option>
                        <option value="MA">MA</option>
                        <option value="MD">MD</option>
                        <option value="ME">ME</option>
                        <option value="MI">MI</option>
                        <option value="MN">MN</option>
                        <option value="MO">MO</option>
                        <option value="MS">MS</option>
                        <option value="MT">MT</option>
                        <option value="NC">NC</option>
                        <option value="ND">ND</option>
                        <option value="NE">NE</option>
                        <option value="NH">NH</option>
                        <option value="NJ">NJ</option>
                        <option value="NM">NM</option>
                        <option value="NV">NV</option>
                        <option value="NY">NY</option>
                        <option value="OH">OH</option>
                        <option value="OK">OK</option>
                        <option value="OR">OR</option>
                        <option value="PA">PA</option>
                        <option value="RI">RI</option>
                        <option value="SC">SC</option>
                        <option value="SD">SD</option>
                        <option value="TN">TN</option>
                        <option value="TX">TX</option>
                        <option value="UT">UT</option>
                        <option value="VA">VA</option>
                        <option value="VT">VT</option>
                        <option value="WA">WA</option>
                        <option value="WI">WI</option>
                        <option value="WV">WV</option>
                        <option value="WY">WY</option>
                        <option value="AS">AS</option>
                        <option value="GU">GU</option>
                        <option value="MP">MP</option>
                        <option value="PR">PR</option>
                        <option value="UM">UM</option>
                        <option value="VI">VI</option>
                    </select>
                    <ErrorMessage name={fields.state.name} />
                </div>
                <div className="form-group postal-code">
                    <label htmlFor={fields.postalCode.id}>ZIP Code</label>
                    <input type="text" {...registerField(fields.postalCode)} />
                    <ErrorMessage name={fields.postalCode.name} />
                </div>
            </fieldset>
            <fieldset className="pac-contact">
                <legend>Contact</legend>
                <div className="form-group first-name">
                    <label htmlFor={fields.firstName.id}>First Name</label>
                    <input type="text" {...registerField(fields.firstName)} />
                    <ErrorMessage name={fields.firstName.name} />
                </div>
                <div className="form-group last-name">
                    <label htmlFor={fields.lastName.id}>Last Name</label>
                    <input type="text" {...registerField(fields.lastName)} />
                    <ErrorMessage name={fields.lastName.name} />
                </div>
                
                <div className="form-group email">
                    <label htmlFor={fields.email.id}>Email</label>
                    <input type="text" {...registerField(fields.email)} />
                    {emailNote && <span className="email-note">{emailNote}</span>}
                    <ErrorMessage name={fields.email.name} />
                </div>
                <div className="form-group phone">
                    <label htmlFor={fields.phoneNumber.id}>Phone (optional)</label>
                    <input type="text" {...registerField(fields.phoneNumber)} />
                    {textMessagingOptions.enabled && textMessagingOptions.label &&
                        <label className="text-messaging" htmlFor={fields.textMessagingOptIn.id}>
                            <input type="checkbox" {...registerField(fields.textMessagingOptIn)}></input>
                            {textMessagingOptions.label}
                        </label>
                    }
                    <ErrorMessage name={fields.phoneNumber.name} />
                </div>
            </fieldset>
        </>
     
    );
}
