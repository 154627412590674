import { useContext } from "react";
import { DonationAmount, DonationPageStateContext, OtherAmount, SuggestedAmount } from "../state";
import "./donation-buttons.css";

export const DonationButtons = ({suggestedDonationAmounts, donationAmount, limit, onChange}:
                                    {suggestedDonationAmounts: number[],
                                     donationAmount: DonationAmount,
                                     limit: number,
                                        onChange: (donationAmount: DonationAmount) => void
                                    }) => {

    // State
    const { state } = useContext(DonationPageStateContext);
    
    // Handlers
    const selectAmount = (amt: string) => {
        onChange(new SuggestedAmount(parseFloat(amt)));
    }

    const onOtherAmountChange = (amt: string) => {
        const amountValue = parseFloat((amt || "0").trim());
        if (isNaN(amountValue)) {
            onChange(new OtherAmount(0));
        } else if (amountValue <= limit || limit == 0) {
            onChange(new OtherAmount(amountValue, amt));
        }
    }

    // Helpers
    const formatAmount = (amt: number) => {
        return new Intl
            .NumberFormat("en-US", { style: "currency", currency: "USD" })
            .format(amt)
            .replace(".00", "");
    }

    // Suggested Amount Buttons
    const suggestedAmountButtons = suggestedDonationAmounts
        .filter(a => limit == 0 || a <= limit)
        .map(amt => {
            const selected = donationAmount && donationAmount.kind == "suggested" && amt == donationAmount.amount;
            const classNames = "amount-option" + (selected ? " selected" : "");
            return (
                <button onClick={e => selectAmount(selected ? "0" : amt.toString())}
                        type="button"
                        className={classNames}
                        key={amt}><span>${amt}</span></button>
            )});

    const classNames = `donation-buttons suggested-amounts-${suggestedAmountButtons.length}`;

    // Other Amount
    let otherAmountDisplayValue = "";
    if (donationAmount.kind == "other" && donationAmount.amount) {
        const oa = donationAmount as OtherAmount;
        otherAmountDisplayValue = oa.amountString;
    }

    let otherAmountCssClass = "other-amount-option";
    if (donationAmount.kind == "other" && donationAmount.amount > 0) {
        otherAmountCssClass += " selected";
    }

    // Limit
    const donationLimit = limit ? `Max ${formatAmount(limit)}` : "No Max";

    // Render
    return (
        <div className={classNames}>
            {suggestedAmountButtons}
            <div className={otherAmountCssClass}><label>$</label><input type="number" min="1" max={limit ? limit : 250000} onChange={(e) => onOtherAmountChange(e.target.value)} value={otherAmountDisplayValue}></input></div>
            {state.donorType != "FederalPac" && <span className="limit">{donationLimit}</span>}
        </div>
    );
}
