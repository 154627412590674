import { useContext } from "react";
import { DonationPageStateContext, RecipientContext } from "../state";
import "./recipient-featured-banner.css";

export const RecipientFeaturedBanner = () => {
    const { state } = useContext(DonationPageStateContext);
    const recipient = useContext(RecipientContext);
    const bannerText = state.configuration.slate.featuredRecipients.bannerText;

    if (!recipient.featured) {
        return <></>;
    } else {
        return (
            <div className="recipient-featured-banner">{bannerText}</div>
        );
    }
}
