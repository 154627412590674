import { ApiError } from "../common";
import { CheckoutForm, CurrentUser, DonationAmount, DonorType, Filters, QueryRecipient, Recipient } from "./state";

export enum ActionType {
    ChangeCurrentViewMode = "CHANGE_CURRENT_VIEW_MODE",
    ClearRecipientAmounts = "CLEAR_RECIPIENT_AMOUNTS",
    LoadCurrentUser = "LOAD_CURRENT_USER",
    LoadData = "LOAD_DATA",
    UpdateFilters = "UPDATE_FILTERS",
    MaxOut = "MAX_OUT",
    PageNotFound = "PAGE_NOT_FOUND",
    CancelMaxOut = "CANCEL_MAX_OUT",
    UpdateDonorType = "UPDATE_DONOR_TYPE",
    UpdateRecipient = "UPDATE_RECIPIENT",
    UpdateRecipientAmount = "UPDATE_RECIPIENT_AMOUNT",
    UpdateRecipientAttribution = "UPDATE_RECIPIENT_ATTRIBUTION",
    UpdateSingleRecipientAmount = "UPDATE_SINGLE_RECIPIENT_AMOUNT",
    UpdateSubscriptionStatus = "UPDATE_SUBSCRIPTION_STATUS",
    SplitAmount = "SPLIT_AMOUNT",
    UpdateCheckoutForm = "UPDATE_CHECKOUT",
    UpdateApiErrors = "UPDATE_API_ERRORS",
    ShowRecipientDialog = "SHOW_RECIPIENT_DIALOG",
    HideRecipientDialog = "HIDE_RECIPIENT_DIALOG",
    ShowProcessingIndicator = "SHOW_PROCESSING_INDICATOR",
    HideProcessingIndicator = "HIDE_PROCESSING_INDICATOR"
}

export type Action =
    | { type: ActionType.LoadCurrentUser, currentUser: CurrentUser }
    | { type: ActionType.LoadData, data: any, queryRecipients: QueryRecipient[], urlSlug: string }
    | { type: ActionType.ClearRecipientAmounts }
    | { type: ActionType.UpdateFilters, filters: Filters }
    | { type: ActionType.UpdateDonorType, donorType: DonorType }
    | { type: ActionType.MaxOut, amount?: number }
    | { type: ActionType.CancelMaxOut }
    | { type: ActionType.UpdateApiErrors, apiErrors: ApiError[]; }
    | { type: ActionType.UpdateRecipient, recipient: Recipient; }
    | { type: ActionType.UpdateRecipientAttribution, identifier: string, attribution: string; }
    | { type: ActionType.UpdateRecipientAmount, identifier: string, amount: DonationAmount; }
    | { type: ActionType.UpdateSingleRecipientAmount, identifier: string, amount: DonationAmount; }
    | { type: ActionType.SplitAmount, amount: number; weighted: boolean; }
    | { type: ActionType.UpdateSubscriptionStatus, subscribe: boolean; }
    | { type: ActionType.UpdateCheckoutForm, checkoutForm: CheckoutForm }
    | { type: ActionType.ShowRecipientDialog, recipientId: string | null }
    | { type: ActionType.HideRecipientDialog }
    | { type: ActionType.ShowProcessingIndicator, message?: string }
    | { type: ActionType.HideProcessingIndicator }
    | { type: ActionType.PageNotFound }